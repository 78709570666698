jQuery(window).load(function() {
    // Get the header parts
    var toolbarBlock = jQuery(".toolbar-block.-fullbleed");
    var headerBlock = jQuery(".header-block.-fullbleed");

    // Create the wrapper
    var stickyHeader = jQuery("<div>", {"class" : "sticky-header"});

    // Insert clones of the header parts into the wrapper
    headerBlock.wrap(stickyHeader);
    toolbarBlock.detach();
    headerBlock.before(toolbarBlock);
    stickyHeader = jQuery(".sticky-header");

    // Insert a clone of the sticky header
    var stickyHeaderClone = stickyHeader.clone();
    stickyHeaderClone.addClass("-stickyfix")
    stickyHeader.after(stickyHeaderClone);
});
